import configs from '../configs';

const sendPostRequest = (url, body) => new Promise((resolve, reject) => {
  const fullUrl = `${configs.apiUrl}${url}`;
  fetch(
    fullUrl,
    {
      method: 'POST',
      headers: {
        'X-API-Key': configs.apiKey,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  )
    .then((response) => response.json())
    .then((json) => (json.status < 200 || json.status >= 400
      ? reject(json)
      : resolve(json)
    ))
    .catch((error) => reject(error));
});

export default sendPostRequest;
