const ENV = process.env.GATSBY_TARGET_ENV || 'development';

const local = {
  apiUrl: 'http://localhost:3001/api/',
  siteUrl: new URL('http://localhost:4000'),
};

const staging = {
  apiUrl: 'https://staging.moank.se/api/',
  siteUrl: new URL('https://staging.iconomic.se'),
};

const production = {
  apiUrl: 'https://api.moank.se/api/',
  siteUrl: new URL('https://www.iconomic.se'),
};

const configs = {
  apiKey: '914c1f240d851d967f42eb124ee673e71a9131d3',
};

if (ENV === 'production') {
  Object.assign(configs, production);
} else if (ENV === 'staging') {
  Object.assign(configs, staging);
} else {
  Object.assign(configs, local);
}

module.exports = configs;
