import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import useModalContext from '../components/modals/useModalContext';

import Section from '../components/logic/layouts/Section';
import Layout from '../components/logic/layouts/Layout';
import SEO from '../components/seo';

import StyledParagraph from '../components/styled/elements/StyledParagraph';
import StyledH3 from '../components/styled/elements/StyledH3';
import StyledH1 from '../components/styled/elements/StyledH1';
import StyledTable from '../components/styled/elements/StyledTable';

import numberFormat from '../utils/numberFormat';

import sendPostRequest from '../utils/sendPostRequest';

const BACKGROUND_COLOR = '#F5F6F6';

const StyledTableRow = styled.tr`
  height: 2.5rem;
  font-size: 1.25rem;
  ${({ focus }) => (focus ? `&:nth-child(even) { background: ${BACKGROUND_COLOR}; };` : '')}
`;

const StyledTableCellHeader = styled.th`
  font-weight: bold;
  text-align: ${({ right }) => (right ? 'right' : 'left')};
  padding: 0 1rem;
  width: (100% - 2rem);
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize' : '')};
  ${({ italic }) => (italic ? 'font-style: italic;' : '')};
`;

const StyledTableCell = styled.td`
  text-align: ${({ right }) => (right ? 'right' : 'left')};
  padding: 0 1rem;
  width: (100% - 2rem);
  text-transform: capitalize;
  ${({ italic }) => (italic ? 'font-style: italic;' : '')};

  ${({ clickable }) => (clickable ? `
    cursor: pointer;
    &:hover {
      background: #DDDDDD;
    }
  ` : '')}
`;

const StyledTableCategorisation = styled.table`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 2rem;

  ${({ reverse, color }) => (reverse ? `
    background-color: #296363;
    color: white;
  ` : `
    border: 3px solid ${color};
  `)}

  tfoot {
    ${({ reverse, color }) => (reverse ? `
      background-color: white;
      color: #034748;
    ` : `
      background-color: ${color};
      color: white;
    `)}
  }

  ${StyledTableRow}:nth-child(even) {
    background: ${({ reverse }) => (reverse ? 'rgba(217, 227, 227, 0.3)' : `${BACKGROUND_COLOR}`)};
  }

  ${StyledH3} {
    color: black;
  }
`;

const COLORS = [
  '#034748',
  '#3C474B',
  '#034748',
  '#3C474B',
];

const fetchData = async (setter) => {
  const data = await sendPostRequest('user/advice-categorisation', {});
  setter(data);
};

const DemoAdvice = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    fetchData(setData);
  }, [fetchData, setData]);

  const { templates, showModal } = useModalContext();
  const showModalClick = useCallback(
    (categoryData) => (e) => {
      e.preventDefault();
      showModal(templates.CATEGORISATION, categoryData);
    },
    [showModal, templates],
  );

  const {
    accounts,
    categorisation,
    periods,
    summary,
  } = data;
  if (!data || !accounts || !categorisation || !periods || !summary) {
    return (
      <Section>
        <StyledParagraph textAlign="center" margin="huge">Reload page to fetch data.</StyledParagraph>
      </Section>
    );
  }
  return (
    <Section>
      <StyledH1 withLine textAlign="center">Överblick över din ekonomi</StyledH1>
      <StyledH3>Konton</StyledH3>
      <StyledTable>
        <thead>
          <StyledTableRow>
            <StyledTableCellHeader>Bank</StyledTableCellHeader>
            <StyledTableCellHeader>Kontonummer</StyledTableCellHeader>
            <StyledTableCellHeader>Saldo</StyledTableCellHeader>
            <StyledTableCellHeader>Namn</StyledTableCellHeader>
            <StyledTableCellHeader>Type</StyledTableCellHeader>
          </StyledTableRow>
        </thead>
        <tbody>
          {accounts.map((account) => (
            <StyledTableRow key={account.accountNumber} focus>
              <StyledTableCell>{account.bank}</StyledTableCell>
              <StyledTableCell>{account.accountNumber}</StyledTableCell>
              <StyledTableCell>{numberFormat(account.balance, 0)}</StyledTableCell>
              <StyledTableCell>{account.name}</StyledTableCell>
              <StyledTableCell>{account.type}</StyledTableCell>
            </StyledTableRow>
          ))}
        </tbody>
      </StyledTable>
      {categorisation.map(({ label, categories, total }, index) => (
        <StyledTableCategorisation key={label} color={COLORS[index]}>
          <caption>
            <StyledH3 textAlign="left">{label}</StyledH3>
          </caption>
          <thead>
            <StyledTableRow>
              <StyledTableCellHeader>Kategorier</StyledTableCellHeader>
              <StyledTableCellHeader focus right italic>Månadssnitt</StyledTableCellHeader>
              {periods.map((date) => (
                <StyledTableCellHeader right capitalize key={date}>{moment(date).format('MMM-YYYY')}</StyledTableCellHeader>
              ))}
            </StyledTableRow>
          </thead>
          <tbody>
            {categories.map((category) => (
              <StyledTableRow key={category.label}>
                <StyledTableCellHeader>{category.label}</StyledTableCellHeader>
                <StyledTableCell focus right italic key={`${category.label}-average`}>{numberFormat(category.average, 0)}</StyledTableCell>
                {category.values.map((value, valueIndex) => (
                  <StyledTableCell
                    key={`${category}-${valueIndex.toString()}`}
                    onClick={showModalClick(value.details)}
                    clickable
                    right
                  >
                    {numberFormat(value.posSum + value.negSum, 0)}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </tbody>
          <tfoot>
            <StyledTableRow key={`${label}-sum`}>
              <StyledTableCellHeader>Summa</StyledTableCellHeader>
              {total.map((value, valueIndex) => (
                <StyledTableCell right key={`${label}-sum-${valueIndex.toString()}`}>
                  {numberFormat(value, 0)}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </tfoot>
        </StyledTableCategorisation>
      ))}
      <StyledTableCategorisation reverse>
        <caption>
          <StyledH3 textAlign="left">Resultat</StyledH3>
        </caption>
        <thead>
          <StyledTableRow>
            <StyledTableCellHeader />
            <StyledTableCellHeader right>Månadssnitt</StyledTableCellHeader>
            {periods.map((date) => (
              <StyledTableCellHeader right capitalize key={date}>{moment(date).format('MMM-YYYY')}</StyledTableCellHeader>
            ))}
          </StyledTableRow>
        </thead>
        <tbody>
          {categorisation.map((category) => (
            <StyledTableRow key={category.label}>
              <StyledTableCellHeader>{category.label}</StyledTableCellHeader>
              {category.total.map((value, index) => (
                <StyledTableCell right key={`${category}-${index.toString()}`}>
                  {numberFormat(value, 0)}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </tbody>
        <tfoot>
          <StyledTableRow>
            <StyledTableCellHeader>Summa</StyledTableCellHeader>
            {summary.map((value, index) => (
              <StyledTableCell right key={`summary-${index.toString()}`}>
                {numberFormat(value, 0)}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </tfoot>
      </StyledTableCategorisation>
    </Section>
  );
};

const DemoAdviceContainer = () => (
  <Layout>
    <SEO title="Rådgivning" />
    <DemoAdvice />
  </Layout>
);

export default DemoAdviceContainer;
